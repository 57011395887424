.home-about-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.home-about-description h1 {
  width: 100%;
  text-align: center;
}

.home-about-description ul {
  width: 100%;
}

.text-top {
  margin-top: 150px;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  

@media (max-width: 768px) {
  .text-top {
    margin-top: 40px;
  }
}
@import url("https://fonts.googleapis.com/css?family=Lato:300");
.shimmer {
    font-family: "Lato";
    font-weight: 600;
    font-size: 3em;
    margin: 0 auto;
    padding: 0 140px 0 0;
    display: inline;
    margin-bottom: 0;
}

.shimmer {
    text-align: center;
    color: #0f0f0f;
    background: -webkit-gradient(linear, left top, right top, from(#0f0f0f), to(#0f0f0f), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#0f0f0f), to(#0f0f0f), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#0f0f0f), to(#0f0f0f), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #0f0f0f;
}

@-moz-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@-o-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

@keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}



.marquee {
    display: block;
    block-size: var(--marquee-item-width);
   
    margin-block: var(--marquee-item-height);
    position: relative;
 
    
    max-inline-size: 100vw;
    
  }
  
  .marquee--8 {
    --marquee-item-width: 20vh;  /* Icon size relative to viewport height */
    --marquee-item-height: auto;
    --marquee-duration: 36s;
    --marquee-items: 8;
    margin-bottom: 50px;
  }
  
  
  .marquee__item {
    flex: none;
    width: var(--marquee-item-width);
    height: var(--marquee-item-height);
    --marquee-item-offset: max(
      calc(var(--marquee-item-width) * var(--marquee-items)),
      calc(100% + var(--marquee-item-width))
    );
    --marquee-delay: calc(var(--marquee-duration) / var(--marquee-items) * (var(--marquee-items) - var(--marquee-item-index)) * -1);
    position: absolute;
    inset-inline-start: var(--marquee-item-offset);
    transform: translateX(-50%);
    animation: go linear var(--marquee-duration) var(--marquee-delay, 0s) infinite;
  }

  .marquee__item2 {
    flex: none;
    width: 8vh;
    height: var(--marquee-item-height);
  }
  
 

  .marquee--8 .marquee__item:nth-of-type(1) {
    --marquee-item-index: 1;
  }
  
  .marquee--8 .marquee__item:nth-of-type(2) {
    --marquee-item-index: 2;
  }
  
  .marquee--8 .marquee__item:nth-of-type(3) {
    --marquee-item-index: 3;
  }
  
  .marquee--8 .marquee__item:nth-of-type(4) {
    --marquee-item-index: 4;
  }
  
  .marquee--8 .marquee__item:nth-of-type(5) {
    --marquee-item-index: 5;
  }
  
  .marquee--8 .marquee__item:nth-of-type(6) {
    --marquee-item-index: 6;
  }
  
  .marquee--8 .marquee__item:nth-of-type(7) {
    --marquee-item-index: 7;
  }
  
  .marquee--8 .marquee__item:nth-of-type(8) {
    --marquee-item-index: 8;
  }
  
  .marquee--3 .marquee__item:nth-of-type(1) {
    --marquee-item-index: 1;
  }
  
  .marquee--3 .marquee__item:nth-of-type(2) {
    --marquee-item-index: 2;
  }
  
  .marquee--3 .marquee__item:nth-of-type(3) {
    --marquee-item-index: 3;
  }
  
  .marquee--6 .marquee__item:nth-of-type(1) {
    --marquee-item-index: 1;
  }
  
  .marquee--6 .marquee__item:nth-of-type(2) {
    --marquee-item-index: 2;
  }
  
  .marquee--6 .marquee__item:nth-of-type(3) {
    --marquee-item-index: 3;
  }
  
  .marquee--6 .marquee__item:nth-of-type(4) {
    --marquee-item-index: 4;
  }
  
  .marquee--6 .marquee__item:nth-of-type(5) {
    --marquee-item-index: 5;
  }
  
  .marquee--6 .marquee__item:nth-of-type(6) {
    --marquee-item-index: 6;
  }
  
  @keyframes go {
    to {
      inset-inline-start: calc(var(--marquee-item-width) * -1);
    }
  }